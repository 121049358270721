<template>
  <div class="min-h-screen bg-white flex">
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <div class="text-3xl lg:text-4xl">💎&nbsp;🙌&nbsp;🏨</div>
          <h2 class="mt-6 text-3xl font-extrabold text-gray-900">
            Room key, please?
          </h2>
          <p class="mt-2 text-sm text-gray-600">
            Or
            {{ ' ' }}
            <router-link :to="{ name: 'Drops' }" class="font-medium text-pink-600 hover:text-pink-500">
              buy a Diamond Hands Hotel NFT
            </router-link>
          </p>
        </div>

        <div class="mt-8">
          <div>
            <div>
              <button @click="sign('SOLANA')" class="w-full flex justify-center py-3 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-base font-medium text-gray-500 hover:bg-gray-50">
                <PhantomIcon class="h-6 w-auto -ml-1.5 mr-2.5" />
                Login with Phantom
              </button>
            </div>
            <div class="mt-4">
              <button @click="sign('ETHEREUM')" class="w-full flex justify-center py-3 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-base font-medium text-gray-500 hover:bg-gray-50">
                <MetamaskIcon class="h-6 w-auto -ml-1.5 mr-2.5" />
                Login with Metamask
              </button>
            </div>

            <!-- <div class="mt-6 relative">
              <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-300" />
              </div>
              <div class="relative flex justify-center text-sm">
                <span class="px-2 bg-white text-gray-500">
                  Or continue with
                </span>
              </div>
            </div> -->
          </div>

          <!-- <div class="mt-6">
            <form action="#" method="POST" class="space-y-6">
              <div>
                <label for="email" class="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div class="mt-1">
                  <input id="email" name="email" type="email" autocomplete="email" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm" />
                </div>
              </div>

              <div>
                <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                  Send magic link
                </button>
              </div>
            </form>
          </div> -->
        </div>
      </div>
    </div>
    <div class="hidden lg:block relative w-0 flex-1">
      <img class="absolute inset-0 h-full w-full object-cover" src="@/assets/img/cover-inner.png" alt="" />
    </div>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { notify } from '@kyvg/vue3-notification'
import { PhantomIcon, MetamaskIcon } from '@ui'

const router = useRouter()
const route = useRoute()
const store = useStore()

const sign = async (chainName) => {
  try {
    await store.dispatch('auth/loginWithWeb3', chainName)
    const next = route.query.next ? decodeURIComponent(route.query.next) : '/roof'
    router.push(next)
  } catch (err) {
    // Show message about error logging in
    notify({ type: 'error', title: 'Unexpected error', text: err.message })
  }
}
</script>